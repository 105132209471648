@use '@angular/material' as mat;
@use './assets/scss/palette.scss' as palette;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

@include mat.core();


$my-primary: mat.define-palette(palette.$quini-app-pallet);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-typography-config: mat.define-typography-config($font-family: 'Roboto',
        $headline-1: mat.define-typography-level(96px, 96px, 300, 'Roboto'),
        $body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto'),
        $body-2: mat.define-typography-level(12px, 24px, 500, 'Roboto'),
        $button: mat.define-typography-level(14px, 14px, 700, 'Roboto Flex'),
    );

$quini-app-web-theme: mat.define-light-theme((color: (primary: $my-primary, accent: $my-accent),
            typography: $my-typography-config, density: 0, ));

html {
    @include mat.core-theme($quini-app-web-theme);
    @include mat.button-theme($quini-app-web-theme);
    @include mat.all-component-themes($quini-app-web-theme);
}

html,
body {
    height: 100vh;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

main {
    background: #F5F8FF;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mat-mdc-form-field {
    border: none !important;
}

.mat-mdc-text-field-wrapper {
    background: white !important;
}

button.example-custom-date-class {
    background: #DEE9FF;
    border-radius: 100%;
}

.toast-bottom-right {
    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 10px;
    color: black !important;
}

.ngx-toastr {
    background-color: #9DF1B8 !important;
    color: black !important;
    text-align: left !important;
    line-height: 1.1 !important;
    font-size: 16px !important;
}

.toast-container .toast {
    margin: 2px !important;
    box-shadow: none !important;
    border-radius: 20px !important;
    background-color: #fff !important;
}

.toast-success {
    background-image: none !important;
}

.toast-close-button {
    color: black !important;
    vertical-align: middle !important;
}

.mat-mdc-dialog-container
  .mdc-dialog__container
  .mat-mdc-dialog-surface{
      border-radius: 10px;

  }